@import url("https://fonts.cdnfonts.com/css/euclid-circular-a?styles=100037,100032,100034,100033,100036,100035,100039,100038,100031,100030");

* {
     margin: 0;
     padding: 0;
     font-family: "Euclid Circular A";

}

hr{
     border: 0.5px solid gainsboro !important
}

.cont{
     width: 25%;
     height: 90vh;
     display: flex;
}
.homeCon{
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100vh;
     flex-direction: column;
     justify-content: space-between;

     width: 100%;
     

}


.erroCon {
    width: 100%;
    height: auto;
    border: 7px solid #22222240;
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 10%;
    border-radius: 5px;
    margin-top: -20%;

}

 .erroCon > p {
     color: red;
     margin-bottom: 10%;
     font-size: 12px;
     text-align: center;
 }

 .erroCon > input {
    /* color: red; */
    margin-bottom: 10%;
    font-size: 12px;
    padding: 5%;
    border: 1px solid gainsboro;
    border-radius: 5px;
}

 .erroCon > button {
     font-size: 13px;
     padding: 4% 5%;
     border: none;
     border-radius: 5px;
     background: #333;
     color: white;
     width: 40%;
     align-self: center;
     cursor: pointer;
 }
 .j {
     display: flex;
     justify-content: center;
     margin-bottom: 3%;
 }
 .j > img {
     width: 40%;
 }
.logo{
        max-width: 150px;
        max-height: 50px;
        margin-top: 5%;
   
}
@media screen and (max-width: 900px) {
    .logo{
        max-width: 150px;
        max-height: 50px;
        margin-top: 10%;
   
}
     .j {
          display: flex;
          justify-content: center;
          margin-bottom: 6%;
      }
     .j > img {
          width: 40%;
      }
     .cont{
          width: 70%;
          height: 90vh;
          display: flex;
     }
     .erroCon {
        width: 100%;
        height: auto;
        border: 7px solid #22222240;
        display: flex;
        flex-direction: column;
        align-self: center;
        padding: 10%;
        border-radius: 5px;
        margin-top: -20%;

    }
      .erroCon > p {
          color: red;
          margin-bottom: 12%;
          font-size: 12px;
          text-align: center;
      }
}

p.infomsg {
    color: #222;
    font-size: 12px;
}